import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const HandCraftedMeal = ({ data, url = false }) => {
  return (
    <div
      className="bg-cover bg-repeat-x show-up"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(146, 146, 146, 0.90),rgba(192, 192, 192, 0.90)),url(${data?.sectionBg?.asset?.url})`,
      }}
    >
      <div className="max-w-[1140px] mx-auto py-10 px-[20px] min-h-[60vh] sm:min-h-[90vh]">
        {/* Heading */}

        <h1 className="text-3xl pt-10 pb-7 sm:text-[50px] text-white text-center  mb-3 font-bold tracking-[2px]">
          {data?.heading}
        </h1>

        {/* Main Image */}
        <div className="w-full sm:w-[65%] pb-[15px] mx-auto">
          <div
            className="w-full rounded-[5px]"
            style={{
              boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
            }}
          >
            {url ? (
              <img
                src={data?.coverImg?.asset?.url}
                alt={data?.coverImg?.alt}
                className="w-full h-full object-cover"
                objectFit="cover"
                loading="lazy"
              />
            ) : (
              <GatsbyImage
                image={data?.coverImg?.asset?.gatsbyImageData}
                alt={data?.coverImg?.alt}
                className="w-full h-full object-cover"
                objectFit="cover"
                loading="lazy"
              />
            )}
          </div>
        </div>
        {/* Cards Container */}
        <div className="w-full sm:w-[90%] pb-[60px] pt-5 mx-auto">
          <div className="grid-svg grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5 items-stretch max-w-full mx-auto align-middle">
            {/* Cards */}
            {data?.foodItem?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col flex-wrap m-0 col-span-1 row-span-1 justify-start overflow-hidden rounded-[5px] bg-white/50"
                >
                  {/* Icon */}

                  <div className="pt-5 pb-[10px] text-center">
                    <Link
                      to={item.link}
                      className="no-underline text-[#006600] active:text-white transition-all duration-200 ease-in"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.svgs,
                        }}
                        className="flex justify-center"
                      />
                    </Link>
                  </div>

                  <h1 className="w-3/4 mx-auto text-[26px] tracking-widest sm:text-3xl text-center leading-10 sm:leading-[45px] mb-3 uppercase text-assent font-bold">
                    {item.heading}
                  </h1>

                  <div className="w-3/4 mx-auto mb-6 font-bold text-assent">
                    {item.detailText}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandCraftedMeal;
